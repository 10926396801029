<template>
  <BaseDialog ref="baseDialog" width="600px" :dialogVisible.sync="dialog" :title="title">
    <template>
      <BaseForm v-loading="loading" ref="form" label-width="100px" :cols="formField" :form="form" :formVisible="dialog">
      </BaseForm>
    </template>
    <template #footer>
      <LoadingBtn size="small" @click="cancel">取消</LoadingBtn>
      <LoadingBtn size="small" :loading="loading" type="primary" @click="doSubmit">确认</LoadingBtn>
    </template>
  </BaseDialog>
</template>
<script>
import formField from './field'
import { commonFromMixin } from '@/mixins'
import { getDiffData, deepClone } from '@/components/avue/utils/util'
import { updateShop as update } from '@/api/shop/manageApi'

export default {
  mixins: [commonFromMixin],
  props: {
    initData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      formField,
      form: {},
      loading: false
    }
  },
  watch: {},
  created() {
    let initData = deepClone(this.initData)
    this.$set(this.form, 'iossTaxNumber', initData.iossTaxNumber)
    this.$set(this.form, 'ukTaxNumber', initData.ukTaxNumber)
  },
  methods: {
    async doSubmit() {
      try {
        await this.$refs['form'].validate()
      } catch {
        return false
      }
      this.loading = true
      let params = getDiffData(this.form, this.initData) || {}
      params.id = this.initData.id
      const [err, res] = await awaitWrap(update(params))
      if ($SUC(res)) {
        this.success()
      }
      this.loading = false
    }
  }
}
</script>
