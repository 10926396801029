import { requireFun } from '@/utils'

export default [
  {
    label: 'IOSS税号',
    prop: 'iossTaxNumber',
    name: 'iossTaxNumber',
    colNum: 18,
    placeholder: '请输入IOSS税号',
    rule: [
      {
        required: true,
        message: requireFun('IOSS税号')
      }
    ]
  },
  {
    label: '英国税号',
    prop: 'ukTaxNumber',
    name: 'ukTaxNumber',
    colNum: 18,
    placeholder: '请输入英国税号',
    rule: [
      {
        required: true,
        message: requireFun('英国税号')
      }
    ]
  }
]
